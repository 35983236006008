
import Vue from 'vue';

import { ERouteName } from '@/domain/core/Routes.enum';

export default Vue.extend({
  name: 'EditoHandmadeBanner',
  data() {
    return {
      handmadePageHref: this.$router.resolve({ name: ERouteName.Handmade }).href,
    };
  },
});
