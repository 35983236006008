
import Vue from 'vue';

import IcAgencyLogo from '@/assets/svg/ic_agency_logo.svg?inline';

export default Vue.extend({
  name: 'EditoProBanner',
  components: {
    IcAgencyLogo,
  },
});
